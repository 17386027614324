import React, { useEffect, useState } from 'react';

import styles from './home.module.css';

import { groupApi } from '../../services/group/group';
import { controlApi } from '../../services/control/control';

import { getFormattedDate, getCurrentDateTime } from '../../utils/date';

function Home() {
  const [gId, setGId] = useState(null);
  const [group, setGroup] = useState([]);
  const [resource, setResource] = useState({});
  const [history, setHistory] = useState({});
  const [historyCnt, setHistoryCnt] = useState(0);

  const [startTime, setStartTime] = useState(null);
  const [targetMp, setTargetMp] = useState({});

  const [isModalOpen, setModalOpen] = useState(false);
  const [errMsg, setErrMeg] = useState('');

  const openModal = () => {
    setModalOpen(true);
    setStartTime(null);
    setErrMeg('');
  };

  const closeModal = () => {
    setModalOpen(false);
    setStartTime(null);
    setErrMeg('');
  };

  useEffect(() => {
    const fetchData = async () => {
      controlApi
        .history()
        .then((res) => {
          setHistory(res.results);
          groupApi
            .list()
            .then((res) => {
              setGroup(res);
              setGId(res[res.length - 1].id);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      groupApi
        .detail(gId, getFormattedDate())
        .then((res) => {
          let controlable_resource = {};
          res.map((data, idx) => {
            if (data.control) {
              controlable_resource[data.id] = {
                name: data.infra.name,
                capacity: data.infra.capacity,
                rtu_id: data.control[0].rtu_id,
              };
            }
          });
          setResource(controlable_resource);

          for (const key in history) {
            if (historyCnt < history[key].length) {
              setHistoryCnt(history[key].length);
            }
          }
        })
        .catch((err) => {});
    };

    if (gId != null) {
      fetchData();
    }
  }, [gId]);

  const handleSelect = (e) => {
    setGId(e.target.value);
    setTargetMp({});
  };

  const handleCheckboxChange = (id) => {
    setTargetMp((prevRows) => {
      // prevRows를 변경하지 않고 새로운 객체를 생성하여 상태를 업데이트합니다.
      const updatedRows = { ...prevRows };

      if (updatedRows.hasOwnProperty(id)) {
        delete updatedRows[id];
      } else {
        updatedRows[id] = -1;
      }

      return updatedRows;
    });
  };

  const handleInputChange = (event, rId) => {
    // rId를 사용하여 원하는 작업 수행
    const value = parseInt(event.target.value);

    // inputs 상태 업데이트 예시
    setTargetMp((prevInputs) => ({
      ...prevInputs,
      [rId]: value,
    }));
  };

  const handleDateTimeChange = (event) => {
    const newDateTime = event.target.value;
    // 선택된 날짜를 상태에 업데이트
    setStartTime(newDateTime);
    // 여기서 선택된 날짜를 저장하거나 다른 작업을 수행할 수 있습니다.
  };

  const totalOff = async () => {
    controlApi
      .totalOff()
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  const totalOn = async (targetMp, startTime) => {
    controlApi
      .totalOn(targetMp, startTime)
      .then((res) => {
        if (res === true) {
          window.location.reload();
        } else {
          setErrMeg(res);
        }
      })
      .catch((err) => {});
  };

  const on = async (targetMp, startTime) => {
    controlApi
      .on(targetMp, startTime)
      .then((res) => {
        if (res === true) {
          window.location.reload();
        } else {
          setErrMeg(res);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className={styles.container}>
      <div>
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modal}>
              <div className={styles.modalTop}>
                <span className={styles.close} onClick={closeModal}>
                  &times;
                </span>
              </div>
              <div>
                <div>
                  <input
                    type="datetime-local"
                    value={startTime}
                    onChange={handleDateTimeChange}
                  />
                </div>
                <div>
                  <span>메세지: </span>
                  <span className={styles.msg}> {errMsg}</span>
                </div>
              </div>
              <div className={styles.modalInner}>
                <div className={styles.history}>
                  <table>
                    <thead>
                      <tr>
                        <th>발전소명</th>
                        <th>설비용량</th>
                        <th>목표출력</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(targetMp).map((rId, idx) => {
                        return (
                          <tr>
                            <td>{resource[rId].name}</td>
                            <td>{resource[rId].capacity}</td>
                            <td>
                              <input
                                type="number"
                                key={rId}
                                onChange={(event) =>
                                  handleInputChange(event, rId)
                                }
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={styles.modalBtm}>
                <button
                  className={styles.modalBtn}
                  onClick={() => totalOn(targetMp, startTime)}
                >
                  전 구간 동일 출력 유지
                </button>
                <button
                  className={styles.modalBtn}
                  onClick={() => on(targetMp, startTime)}
                >
                  다음 구간 전까지만 출력 유지
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className={styles.contentHeader}>
          <select onChange={handleSelect} value={gId}>
            {group &&
              gId &&
              group.map((data, idx) => {
                return (
                  <option value={data.id} key={idx}>
                    {data.name}
                  </option>
                );
              })}
          </select>
          <button onClick={openModal} className={styles.modalBtn}>
            제어 등록하기
          </button>
          <button onClick={totalOff} className={styles.modalBtn}>
            모든 발전소 제어 취소
          </button>
        </div>
        <div className={styles.history}>
          <table>
            <thead>
              <tr>
                <th>선택</th>
                <th>발전소명</th>
                <th>용량</th>
                <th>제어기록</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(resource).map((rId, idx) => {
                let data = resource[rId];
                return (
                  <>
                    <tr key={rId}>
                      <td rowSpan="2">
                        <input
                          type="checkbox"
                          checked={targetMp.hasOwnProperty(rId)}
                          onChange={() => handleCheckboxChange(rId)}
                        />
                      </td>
                      <td rowSpan="2">{data.name}</td>
                      <td rowSpan="2">{data.capacity}</td>
                      {history[rId]?.map((his, idx2) => {
                        return <td>{his.start_time}</td>;
                      })}
                    </tr>
                    <tr>
                      {history[rId]?.map((his, idx2) => {
                        return <td>{his.target_mp} kW</td>;
                      })}
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Home;
