const API_PREFIX = 'api';
const WS_PREFIX = 'ws';
const MEDIA_PREFIX = 'media';

export const API = {
  AUTH: {
    BASE: `/${API_PREFIX}/user/token/`,
    VERIFY: `/${API_PREFIX}/user/token/verify/`,
    REFRESH: `/${API_PREFIX}/user/token/refresh/`,
  },
  RESOURCE: `/${API_PREFIX}/resource`,
  GROUP: `/${API_PREFIX}/group`,
  CONTROL: `/${API_PREFIX}/control`,
};
