import { API } from '../constants';
import request from '../api';

export const controlApi = {
  history: async () => {
    return request
      .request('get', `${API.CONTROL}/plants/total/`)
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });
  },
  totalOff: async () => {
    return request
      .request('post', `${API.CONTROL}/plants/total/off/`)
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });
  },
  totalOn: async (targetMp, startTime) => {
    let data = {
      plant: targetMp,
      start_time: startTime,
    };

    return request
      .request('post', `${API.CONTROL}/plants/total/on/`, data)
      .then((res) => {
        if (res.status == 200) {
          return true;
        }
        return res.data.message;
      })
      .catch((err) => {
        return err;
      });
  },
  on: async (targetMp, startTime) => {
    let data = {
      plant: targetMp,
      start_time: startTime,
    };

    return request
      .request('post', `${API.CONTROL}/plants/on/`, data)
      .then((res) => {
        if (res.status == 200) {
          return true;
        }
        return res.data.message;
      })
      .catch((err) => {
        return err;
      });
  },
};
