import { API } from '../constants';
import request from '../api';

export const groupApi = {
  list: async () => {
    return request
      .request('get', `${API.GROUP}/total/`)
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });
  },
  detail: async (g_id, date) => {
    return request
      .request('get', `${API.GROUP}/${g_id}/resource/?date=${date}`)
      .then((res) => {
        if (res.status == 200) {
          return res.data;
        }
        return false;
      })
      .catch((err) => {
        return err;
      });
  },
};
