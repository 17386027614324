import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import styles from './nav.module.css';

import { HiChevronDown, HiChevronLeft, HiEmojiHappy } from 'react-icons/hi';

function Nav() {
  const [page, setPage] = useState('');
  const [submenu, SetSubmenu] = useState({
    resource: false,
    sub2: false,
  });

  const MyLink = ({ ICON, mypage, to }) => {
    return (
      <Link
        className={`${styles.navItem} ${page == mypage ? styles.me : ''}`}
        to={`/${to}`}
        onClick={() => setPage(mypage)}
      >
        <div className={styles.itemBox}>
          <div className={styles.innerIcon}>
            {ICON ? <ICON size={25} /> : <div>&nbsp;</div>}
            <div>{mypage}</div>
          </div>
          <div>&nbsp;</div>
        </div>
      </Link>
    );
  };

  const toggle = (menu) => {
    SetSubmenu((submenu) => {
      let newSubmenu = { ...submenu };
      newSubmenu[menu] = !submenu[`${menu}`];
      return newSubmenu;
    });
  };

  return (
    <div>
      <ul className={styles.navigation}>
        <li>
          <MyLink ICON={HiEmojiHappy} mypage={'Home'} to={''} />
        </li>
      </ul>
    </div>
  );
}

export default Nav;
